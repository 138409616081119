.card {
    margin-top: 1em;
}

.card-body {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.projects-card-text {
    padding-left: 1em;
    padding-right: 1em;
}

.project-bottom-card {
    margin-bottom: 1em;
}